#footer {
    background: $io-blue-medium;
    color: $io-white;

    @media (max-width: $screen-xs-max) {
        padding-top: 20px;
    }

    > .container {
        padding-left: 37px;
        padding-right: 37px;

        &:first-of-type {
            padding: 30px 37px 20px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 0 20px;
        }

        > .row {
            margin: 0;
        }
    }

    h1, h2, h3, h4, h5, h6,
    a {
        color: $io-white;
    }

    h4 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    h5 {
        font-size: inherit;
        font-weight: 600;
    }

    section {
        padding: 0;

        &:nth-child(2) {
            padding: 0 10px;

            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
        }

    }

    #blockcontactinfos {
        ul.list-unstyled {
            @media (max-width: $screen-sm-max) {
                &:last-child {
                    margin-top: 18px;
                }
            }

            li {
                &:first-child,
                &:nth-child(3) {
                    margin-bottom: 20px;
                }

                i.icon {
                    width: 15px;
                }
            }
        }
    }

    #blockcms-footer {
        ul {
            list-style: disc;
            list-style-position: outside;
            margin-left: 16px;
            padding: 0;

            li {
                padding: 0;
            }
        }
        @media (max-width: $screen-xs-max) {
            margin-bottom: 35px;
        }
    }

    #footer-social-mobile {
        ul {
            text-align: center;
            @media (max-width:$screen-xs-max){
                text-align: left;
            }
            
            li {
                display: inline-block;
                padding: 0 10px 10px;
            }
        }
    }

    #footer-social {
        li {
            margin-bottom: 10px;

            a {
                svg {
                    margin-right: 3px;
                    vertical-align: middle;
                }
            }
        }
    }
}

#copyright-footer-wrap {
    background: $io-blue-dark;
    width: 100%;

    .row {
        display: table-cell;
        vertical-align: middle;
        height: auto;
        width: 100%;
    }

    a,
    a:focus,
    a:active {
        text-decoration: none;
    }

    span {
        font-family: $font-heading;
        font-size: 16px;
        font-weight: 200;
        padding-right: 6px;
        vertical-align: middle;
    }
}

#copyright-footer {
    float: right;
    height: 70px;
    padding: 15px 0;
    text-align: right;
    vertical-align: middle;
    width: 100%;

    h1, h2, h3, h4, h5, h6 {
        display: inline-block;
        color: $io-white;
    }
}