// .product-name {}

.pb-center-column > h1{
    margin-bottom: 5px !important;
}
.price {
    white-space: nowrap;
     //&.product-price {}
}

.old-price {
    color: $io-grey-medium;
    text-decoration: line-through;
    // &.product-price {}
}

.special-price {
    color: $io-red;
}

.price-percent-reduction {
    background-color: $io-red;
    color: $io-white;
    padding: 0 0.25em;
    display: inline-block;
    // &.small {}
}

.product-label-container {
    position: absolute;
    top: 0;
    left: 0;
    @include clearfix();
    .product-label {
        float: left;
    }
}

#product_reference{
    font-family: $font-heading;
    color: lighten($text-color,10);
    label{
        text-transform: uppercase;
    }
}
.product-label {
    font-size: 0.9em;
    margin: 4px 0 0 4px;
    padding: 2px 5px;
    font-weight: bold;
    background-color: #CCCCCC;
    color: #FFFFFF;
    @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
    &:last-of-type {
        margin-right: 0;
    }
}

.product-label-new {
    background: #6AD4FF;
}

.product-label-sale {
    background: $io-red;
}

.product-label-discount {
    background-color: $io-red;
}

.product-label-online {
    background-color: $io-yellow;
}
