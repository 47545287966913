//
// Common Order Page Styles
//

.not-displayable {
  display: none;
}

#order_step {
  margin-bottom: 15px;
  .completed {
    background: #eee;
  }
}

//
// Step 1: Cart Summary
//

.price_discount_delete {
  font-size: 2em;
}

.total_price_container
 {

  div{
    display: inline-block;
  }
}
#total_price_container{
  font-size: 22px !important;
  font-weight: 700;
}

#cart_summary #cart_voucher {
  text-align: left;
}

#cart_voucher {
  fieldset {
    margin-bottom: 10px;
  }

}

#discount_name {
  float: left;
  width: 75%;
  max-width: 200px;
  height: 32px;
}

#display_cart_vouchers {
  span {
    font-weight: bold;
    cursor: pointer;
    color: $link-color;
    &:hover {
      color: $link-hover-color;
    }
  }
}

#cart_summary {
  .gift-icon {
    color: #fff;
    background-color: #0088cc;
    display: inline-block;
    padding: 2px 5px;
  }
}

#cart_summary_new {
  hr{
    margin-bottom: 8px;
  }
  .aside_heading{
    float:left;
    margin: 5px 0;
    @media (max-width: $screen-xs-max) {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      text-align: left;

    }
  }

 div.input-group > *,
 div.input-group .input-group-btn > *{
    height: 32px;
  }





  padding-top: 20px;
  label:after{
    content:':';
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2px;
    width: 5px;
    height: 30px;
  }
  .cart_product_description_wrapper {
    label:after{
      display: none;
    }
  }
  .price{
    min-width: 85px;
    display: inline-block;
    text-align: right;
  }
  .price,
  .quantity{
    font-size: 18px;
    &.summary,
      &.total{
      font-size: 20px;
    }
  }
  .cart_price.mobile{
    width: 100%;
    label{
      padding-top: 5px;
    }
  }

  .cart_unit{
    > * ,
    ul.price > *{
      float: right;
      clear: both;

    }
  }
  .cart_total_voucher{
    text-align: right;
  }
  .cart_total_price {
    text-align: right;

    > * {
      display: inline-block;
    }

  }
  .cart_total_display{
    hr{
      background-color: $io-grey-medium;
    }

    .cart_total_price .hookDisplayProductPriceBlock-price,
    label,span{
      display: inline-block;
      font-size: 20px;
    }
    .cart_total_price.absolute{
      label,span{
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
  .cart_product_table,
  .cart_giftcard_table{
    .cart_item:last-child {
      .row{
        > hr {
          opacity: 0;
        }
      }
    }
  }
  .cart_price.mobile{
    margin: 15px 0;
    .price-container{
      > * {
        display: inline-block;
      }
    }
  }
  .cart_actions.mobile{
    > * {
      float: none;
      margin: 0 10px 15px 0 !important;
      display: inline-block;
      &:last-child{
        margin: 0 0px 15px 0 !important;
      }

    }
    .cart_quantity_input_wrapper{
      width:100%;
      margin: 0 0 15px 0 !important;
      input{
        float: none;
        display: inline-block;
      }
      label{
        padding-top: 5px;
      }

    }
    .cart_quantity_input{
      width: 200px;
      padding: 0;
    }
  }

  .cart_product{
    .img-overlay-wrapper{
      width: 100%;
    }
    a{
      float: left;
      width: 100%;
    }
  }
  .cart_quantity_input{
    width: 50%;
    padding: 20px 10px;
    margin: 0 25% 15px 25%;
  }
  .cart_quantity_delete{
    width: 50%;
    margin: 0 25% 15px 25%;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0 5px;
    .cart_actions.mobile{
      .cart_quantity_input{
        width: 100%;
        margin: 0;
      }

    }
    .cart_product{
      .img-overlay-wrapper{
        height: 100px;
      }
    }
    .cart_product_description_wrapper {
      font-size: 16px;
    }
    .cart_button_remove {
      float: right
    }
    .cart_button_edit {
      float: right;
      text-transform: uppercase;
      margin-right: 10px;
      label {
        margin-right: 5px;
        margin-bottom: 0;
      }
    }
    .price,
    .quantity{
      font-size: 20px;
      &.summary,
      &.total{
        font-size: 22px;
      }
    }

  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
    .cart_product{
      .img-overlay-wrapper{
        height: 150px;

      }
    }
  }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max){
    .cart_product{
      .img-overlay-wrapper{
        height: 180px;

      }
    }
  }
  @media (min-width: $screen-lg-min){
    .cart_product{
      .img-overlay-wrapper{
        height: 180px;
      }
    }
  }
  .cart_head {
    margin-bottom: 15px;
    font-size: 18px;
    @media (max-width: $screen-xs-max) {
      font-size: 20px;
      &.cart_price {
        text-align: right;
      }
    }
  }
  .cart_close_form_wrapper {
    padding: 5px 15px;

  }
    .cart_form_wrapper {
      .collapse {
        .cart_form_inner_wrapper {
          background-color: yellow;
          display: table;
          @include transition(all 350ms);
          padding: 20px 0;

        }
        &.in {
          .cart_form_inner_wrapper {
            margin-top: 15px;
            background-color: lighten($brand-info, 30%);
          }

        }
      }
      .cart_form_inner_wrapper {

        padding: 20px 0;
      .cart_form_inner_buttons {
        position: absolute;
        z-index: 1;
        z-index: 1;
        top: 30px;
        right: 20px;
        .cart-btn-preview {
          margin-right: 10px;
          padding: 6px 10px;
        }
      }
      }
    }
  }

ul.address.box{
  border: 1px solid $io-grey-light;
  background-color: #fff;
}
div.order_delivery > *{
  float: right;
}

//
// Step 3: Address
//

#id_address_invoice,
#id_address_delivery {
  width: 270px;
}

.address_delivery,
#address_invoice_form {
  position: relative;
}

.addresses {
  .waitimage {
    display: none;
    width: 24px;
    height: 24px;
    background-image: url("../../../img/loader.gif");
    background-repeat: no-repeat;
    position: absolute;
    top: 31px;
    left: 280px;
  }
}

.multishipping_close_container {
  text-align: center;
}

//
// Step 4: Carriers
//

.delivery_option {
  table {
    background: #fff;
    > tbody > tr > td {
      vertical-align: middle;
    }
  }
}

.delivery_option_radio {
  width: 20px;
}

.delivery_option_logo {
  width: 90px;
  text-align: center;
}

//.delivery_option_price { width: 160px; }

.carrier_title {
  font-weight: bold;
}

#gift_div {
  display: none;
}

//
// Step 5: Payment
//

.payment_module {
  a {
    display: block;
    border: 1px solid $table-border-color;
    font-size: 1.25em;
    font-weight: bold;
    padding: 33px 40px 34px 99px;
    position: relative;
    &:hover {
      background-color: #f6f6f6;
    }
    &.bankwire {
      background: url('../img/bankwire.png') 15px 12px no-repeat #fbfbfb;
    }
    &.cheque {
      background: url('../img/cheque.png') 15px 15px no-repeat #fbfbfb;
    }
    &.cash {
      background: url('../img/cash.png') 15px 15px no-repeat #fbfbfb;
    }
    &.cheque,
    &.bankwire,
    &.cash {
      &:after {
        display: block;
        content: "\f054";
        position: absolute;
        right: 15px;
        margin-top: -11px;
        top: 50%;
        font-family: $font-icon;
        font-size: 25px;
        height: 22px;
        width: 14px;
      }
    }
  }
}

//
// Order: Advanced
//

.payment_option_form {
  display: none;
}

.payment_option_logo {
  height: 50px;
  max-height: 50px;
}

.payment_module_adv {
  height: 120px;
  max-height: 120px;
}

.payment_option_selected {
  i {
    color: green;
  }
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  @include translate(-50%,-50%);
}




