.alert {
  font-weight: bold;
  ul, ol {
    padding-left: 15px;
    margin-left: 27px;
    margin-bottom: 0;
    li {
      list-style-type: decimal;
      font-weight: normal;
    }
  }
  &.alert-success:before {
    font-family: $font-icon;
    margin-right: 0.5em;
    content: "\f058"; // fa-check-circle
  }
  &.alert-danger:before {
    font-family: $font-icon;
    margin-right: 0.5em;
    content: "\f057"; // fa-times-circle
  }
  &.alert-warning:before {
    font-family: $font-icon;
    margin-right: 0.5em;
    content: "\f06a"; // fa-exclamation-circle
  }
  &.alert-info:before {
    font-family: $font-icon;
    margin-right: 0.5em;
    content: "\f05a"; // fa-info-circle
  }
}
