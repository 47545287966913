@import "theme_variables";
@import "vendor_variables";

.btn {
  padding: 6px 20px;
}

.thumbnail {
  border-radius: 0;
}

hr {
  margin-bottom: 30px;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $io-blue-dark;
  font-family: $font-heading;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-primary {
  @include transition(300ms);
  &:hover {
    background-color: $io-white;
    color: $btn-primary-bg;
  }

}

.pointer {
  cursor: pointer;
}

@media (max-width: $screen-xs-max) {
  .visible-xs-inline {
    display: inline-block !important;
  }
}

@media (min-width: $screen-sm-min) {
  .visible-xs-inline {
    display: none;
  }
}

.large-space {
  margin-bottom: 60px;
}

.medium-space {
  margin-bottom: 35px;
}

.small-space {
  margin-bottom: 15px;
}

.tiny-space {
  margin-bottom: 5px;
}

.text-right-xs {
  text-align: right !important;
}

.large-space-xs {
  margin-bottom: 60px !important;
}

.medium-space-xs {
  margin-bottom: 35px !important;
}

.small-space-xs {
  margin-bottom: 15px !important;
}

.tiny-space-xs {
  margin-bottom: 5px !important;
}

.no-space-xs {
  margin-bottom: 0 !important;
}

.pull-right-xs {
  float: right;
}

.pull-left-xs {
  float: left;
}

.text-left-xs{
  text-align: left;
}

.text-right-xs{
  text-align: right;
}




body {
  &.no-scroll {
    overflow-y: hidden;
  }
  .search,
  .category {
    #center_column .products-wrapper {
      > h1.page-heading {
        padding: 0 20px;
      }
    }
  }
}

  //@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @media (min-width: $screen-sm-min) {
    .pull-right-sm {
      float: right !important;
    }

    .pull-left-sm {
      float: left !important;
    }

    .text-left-sm{
      text-align: left !important;
    }

    .text-right-sm{
      text-align: right !important;
    }

    .large-space-sm {
      margin-bottom: 60px !important;
    }
    .medium-space-sm {
      margin-bottom: 35px !important;
    }
    .small-space-sm {
      margin-bottom: 15px !important;
    }
    .tiny-space-sm {
      margin-bottom: 5px !important;
    }
    .no-space-sm {
      margin-bottom: 0 !important;
    }
  }

  //@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @media (min-width: $screen-md-min) {
    .pull-right-md {
      float: right !important;
    }

    .pull-left-md {
      float: left !important;
    }

    .text-left-md{
      text-align: left !important;
    }

    .text-right-md{
      text-align: right !important;
    }

    .large-space-md {
      margin-bottom: 60px !important;
    }
    .medium-space-md {
      margin-bottom: 35px !important;
    }
    .small-space-md {
      margin-bottom: 15px !important;
    }
    .tiny-space-md {
      margin-bottom: 5px !important;
    }
    .no-space-md {
      margin-bottom: 0 !important;
    }
  }

  @media (min-width: $screen-lg-min) {
    .pull-right-lg {
      float: right !important;
    }

    .pull-left-lg {
      float: left !important;
    }

    .text-left-lg{
      text-align: left !important;
    }

    .text-right-lg{
      text-align: right !important;
    }

    .large-space-lg {
      margin-bottom: 60px !important;
    }
    .medium-space-lg {
      margin-bottom: 35px !important;
    }
    .small-space-lg {
      margin-bottom: 15px !important;
    }
    .tiny-space-lg {
      margin-bottom: 5px !important;
    }
    .no-space-lg {
      margin-bottom: 0 !important;
    }
  }

  div.force-table {
    display: table;
    vertical-align: middle;
    width: 100%;
    .forced-child {
      display: table-cell;
      vertical-align: middle;
      float: none;
    }
  }

  img.img-responsive.ghost {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  img.img-responsive.overlay {
    position: absolute;
    max-width: 100%;
    display: block;
    width: 100%;
    z-index: 1;
  }

  div[class*="col-"] {
    img.img-responsive.overlay {
      max-width: calc(100% - 10px);

    }
  }

  div.img-overlay-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;

    img.img-responsive {
      position: absolute;
      width: auto;
      height: 100%;
      max-width: none;
      @include translate(-50%, 0);
      left: 50%;
    }

  }

  .alert {
    position: relative;
    padding-left: 50px;

    > * {
      margin-left: 0 !important;
    }
    &:before {
      font-size: 25px;
      line-height: 1em;
      position: absolute;
      top: 50%;
      left: 25px;
      @include translate(-50%, -50%);
    }
  }

  .alert-danger,
  .alert-info,
  .alert-success,
  .alert-warning {
    border-width: 10px;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0;
    &:before {
      margin-right: 0;
    }
  }

  .box {
    border: none;
    background-color: $io-grey-light;
    padding: 25px;
    border-radius: 0;
    .page-subheading {
      border-color: $io-grey-medium;
    }
    .box {
      background-color: $io-grey-medium;
      border-color: $io-grey-dark;
    }
  }

  .fancybox {

    div.preview {
      width: 100%;
      position: relative;

      img.preview {
        position: relative;
        height: auto;
        width: 150%;
        max-width: 150%;
        &.overlay {
          position: absolute;
          top: 0;
          z-index: 1;
        }
      }
    }
  }


  @media (max-width: $screen-xs-max) {
    .store-counter {
      .store-phrase {
        display: none;
      }
    }
    body.search,
    body.category {
      #center_column .products-wrapper {
        > h3.page-heading {
          border-bottom: none;
          padding: 0 15px;
        }
      }
    }
  }

  h1, .h1 {
    font-size: 23px;
  }

  .fancybox-iframe {
    overflow:visible!important;
  }
div.fancybox-inner{
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

  .popover{
    table{
      margin-bottom: 0;
      td > * {
        float: left;
      }
      .add_to{
        cursor: pointer;
        i{
          margin-right:10px;
        }
      }
    }
  }

  #burger-menu{
    ul{
      padding-bottom: 60px;
      li{
        a{
          padding-right: 0;

          margin-bottom: 5px;
          &:last-child{
            margin-bottom: 0;
          }
        }
        &:last-child{
          border: none;
        }
      }
    }
  }

.btn.btn-cart {
  width:  70px;
  height: 70px;
  text-align: center;
  padding: 0;
  background: transparent;
  color: $io-blue-light;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  @include transition(350ms all);
  i{
    &:before{
      margin-left: -3px;
    }
    font-size: 36px;
  }
  &:hover{
    color: $io-green;
  }
  @media (max-width:$screen-xs-max){
    width:  55px;
    i {
      font-size: 30px;
    }
  }
}
body.category,
body.getgiftcard
{
  #buy_block{
    width:70px;
    @media (max-width:$screen-xs-max){
      width: 55px;
    }
  }
}

.product-actions-container{
  .product-price{
    width: 100%;
    font-size: 18px;
    padding-right: 20px;
    text-align: right;
    i.icon{
      vertical-align: -10%;
    }
    @media (max-width:$screen-xs-max) {
      padding-right: 0;
      font-size: 16px;

    }
  }
}

.store-features{
  span.more-tag{
    cursor: pointer;
    background: transparent;
    color: #fff;
    background: $io-blue-medium;
    font-family: $font-heading;

    @include transition(350ms all);
    &:hover{
      background: #ffffff;
      color: $io-blue-medium;
    }
    span{
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
    }

  }
}