body {
    background-color: $io-white;
    overflow-x: hidden;

    &.content_only {
        background-color: $io-white;
    }
}

#header-blocks {
    @include transition(all 350ms);

    background-color: $io-blue-medium;
    padding-top: 24px;

    position: relative;

    @media (max-width: $screen-xs-max) {
        padding-top: 5px;
    }

    .row {
        max-height: 115px;
        padding: 0 $column-padding;

        @media (max-width: $screen-xs-max) {
            padding: 0;
        }

        #social_block {
            float: right;
        }
    }
}

#bigBanner {
    z-index: 1;
    @media (min-width: $screen-sm-min) {
            //position: absolute;
    }
}

#columns {
    background-color: transparent;
    top: -$io-pillar-space;
    margin-bottom: (4 - $io-pillar-space );
    padding-left: $column-padding;
    padding-right: $column-padding;
    position: relative;
    z-index: 2;

    @media (max-width: $screen-xs-max) {
        margin-bottom: 0;
        top: 0;
        padding: 0;
    }

    > * {
        padding-left: 0;
        padding-right: 0;
    }

    > .row:not(#top_column) {
        background-color: $io-white;
        margin-left: (- $top_column_gutter);
        margin-right: (- $top_column_gutter);

        @media (max-width: $screen-xs-max) {
            margin: 0;
        }
    }
}

#top_column {
    background-color: $io-white;
    margin: 0;
    padding: 4px 0 0 0;

    @media (max-width: $screen-xs-max) {
        overflow: hidden;
    }

    @media (min-width: $screen-sm-min) {
        margin: (- $top_column_gutter);
        padding: 4px 6px 0 7px;
    }
}

#left_column {
    box-sizing: border-box;
    width: $left-column-width;
    padding: 0 0 0 4px;
    @media (max-width: $screen-md-max) {

    }

    @media (max-width: $screen-sm-max) {

    }

    @media (max-width: $screen-xs-max) {
        display: none;
    }
}



body.search,
body.category {
    #center_column {
        padding: 0 2px;

        @media (min-width: $screen-lg-min) {
            width: 80%
        }
        @media (max-width: $screen-md-max) {
            width: 80%
        }
        @media (max-width: $screen-sm-max) {
            width: 80%;
            padding: 0;
        }
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }

        .product-list-banner {
            margin-bottom: 4px;

            @media (max-width: $screen-xs-max) {
                width: 100%;

                &.above {
                    .small-banner {
                        display: none;
                    }
                }
                &.below {
                    .medium-banner {
                        display: none;
                    }
                }
            }
        }

        .medium-banner,
        .small-banner {
            text-align: center;

            a {
                display: inline-block;
            }

            img {
                @include img-responsive();
            }
        }
        .small-banner{
            padding-left: 0;
        }

        .medium-banner,
        .google-map,
        .products-wrapper {
            padding: 0 4px 0 8px;

            #medium-banner {
                overflow: hidden;

                img {
                    position: inherit;
                    @include translate(0, 0)
                }
            }

            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
        }

        #thomaxGoogleMap {
            background-color: $io-white;
            max-height: 266px;

            & > div {
                left: inherit !important;
                padding: 0 4px;
                position: inherit !important;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    #category-products {
        & > .row {
            margin: 0;
        }
    }

    #replaceContent {
        margin: 0;
        width: 100%;
    }
}
body.getsellable,
body.product {
    .pb-left-column {
        padding-right: 30px;
    }
    .pb-right-column {
        padding-left: 30px;
    }

    .pb-center-column {
        padding-left: 30px;
    }

    @media (max-width: $screen-xs-max) {
        .pb-left-column,
        .pb-right-column,
        .pb-center-column {
            padding: 0 5px;
        }
    }
}

#header-banners {
    background-color: #000000;
}

#header {
    @include transition(all 350ms);
    background: $io-blue-medium;
    font-family: $font-heading;

    .navbar {
        margin-bottom: 0;
    }

    @media(max-width: $screen-xs-max){
        &.sticky{
            width: 100%;
            position: fixed;
            height: 55px;
            top:  0;
            left: 0;
            z-index: 99;
            #header-blocks{

                padding-top: 0;
            }
        }

    }
}

#shop-logo {
    background-color: $io-blue-dark;
    height: 115px;
    text-align: center;
    z-index: 25;
    @include border-top-radius(5px);

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        height: 65px;

    }

    @media (max-width: $screen-xs-max) {
        height: 55px;
        background-color: transparent;
        text-align: center;
        width: 180px;

        &.opacity {
            a {
                opacity: 0;
            }
        }

        &.highlight {
            a {
                height: 54px;
                left: 85px;
                max-width: 160px;
                opacity: 1 !important;
                position: fixed;
            }
        }
    }

    a {
        background: url(../img/logo.svg) center center no-repeat;
        background-size: 100% auto;
        display: inline-block;
        height: 100%;
        width: 75%;
        @include transition(opacity 250ms ease-in);

        @media (max-width: $screen-xs-max) {
            background-position: left 6px;
            background-size: auto 40px;
            width: 100%;
        }
    }
}

#burger {
    position: inherit;
    z-index: 20;

    @media (max-width: $screen-xs-max) {
        width: 80px;
    }

    .hamburger {
        background-color: transparent;
        border: 0;
        color: inherit;
        cursor: pointer;
        display: inline-block;
        font: inherit;
        margin: 14px 0 0 0;
        overflow: visible;
        padding: 0 0 0 15px;
        position: absolute;
        text-transform: none;
        transition-duration: 0.15s;
        transition-property: opacity, filter;
        transition-timing-function: linear;
        z-index: 30;
    }

    .hamburger:hover {
        opacity: 0.7;
    }

    .hamburger-box {
        display: inline-block;
        height: 24px;
        position: relative;
        width: 40px;
    }

    .hamburger-inner {
        display: block;
        margin-top: -2px;
        top: 50%;
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: $io-white;
        border-radius: 4px;
        height: 4px;
        position: absolute;
        transition-duration: 0.15s;
        transition-property: transform;
        transition-timing-function: ease;
        width: 40px;
    }

    .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block;
    }

    .hamburger-inner::before {
        top: -10px;
    }

    .hamburger-inner::after {
        bottom: -10px;
    }

    .hamburger--slider .hamburger-inner {
        top: 2px;
    }

    .hamburger--slider .hamburger-inner::before {
        top: 10px;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.2s;
    }

    .hamburger--slider .hamburger-inner::after {
        top: 20px;
    }

    input[type=checkbox] {
        height: 30px;
        margin: 12px 0 0 15px;
        padding: 0;
        position: absolute;
        width: 40px;
        z-index: 35;
        @include opacity(0);
    }

    input[type=checkbox]:checked {
        position: fixed;

        & + .hamburger {
            position: fixed;

            .hamburger-inner {
                transform: translate3d(0, 10px, 0) rotate(45deg);
            }

            .hamburger-inner::before {
                transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                opacity: 0;
            }

            .hamburger-inner::after {
                transform: translate3d(0, -20px, 0) rotate(-90deg);
            }
        }
    }

    input[type=checkbox] {
        & + .hamburger {
            & + #burger-menu {
                background-color: $io-blue-medium !important;
                border-top: 70px solid $io-blue-medium;
                bottom: 0;
                color: $io-white;
                left: -75%;
                opacity: 0;
                padding: 0 25px;
                position: fixed;
                top: 0;
                width: 75%;
                z-index: 20;
                @include box-shadow(0 0 0 0 rgba(0, 0, 0, 0));
                @include transition(all 250ms ease);
            }
        }

        &:checked + .hamburger {
            & + #burger-menu {
                left: 0;
                opacity: 1;
                overflow: auto;
                outline: 1px solid rgba(0, 0, 0, 0.50);
                @include box-shadow(0 0 100px 50px rgba(0, 0, 0, 0.50));
            }
        }
    }

    /* First lvl */
    ul {
        li {
            padding: 10px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.25);

            /* Second lvl */
            & > ul {
                display: none;
                padding: 10px 10px 0;

                li {
                    border: none;
                }
            }

            &.children {
                &:before {
                    content: '';
                    height: 28px;
                    width: 28px;
                    background: url(../img/arrow.svg) calc(100% - 5px) center no-repeat;
                    background-size: 20px;
                    display: inline-block;
                    float: right;
                    position: relative;
                    @include transition(all 250ms ease);
                    @include rotate(90deg);
                }
            }

            &.open {
                &:before {
                    @include rotate(0deg);
                }

                ul {
                    display: block;
                }
            }
        }

        a {
            color: $io-white;
            display: inline-block;
            font-size: 20px;
            padding-right: 30px;
            width: calc(100% - 28px);

            &:focus {
                color: $io-grey-medium;
                text-decoration: none;
            }
        }
    }
}

#desktop-menu {
    background-color: $io-blue-light;
    border: none;
    float: left;
    font-family: $font-heading;
    font-weight: 200;
    height: 50px;
    line-height: 48px;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 9;

    @include transition(all 3Ch50ms ease);

    ul {
        display: block;

        li {
            float:left;
            height: 50px;
            @include transition(all 250ms ease);
            &:hover {
                background: $io-blue-medium;
            }

            &.home {
                a {
                    font-size: 16px;
                    padding: 12px 20px 16px;

                    &:after {
                        background: url(../img/home.svg) center center no-repeat;
                        background-size: 20px;
                        content: '';
                        display: inline-block;
                        height: 20px;
                        vertical-align: text-top;
                        width: 20px;
                    }
                }
            }

            &.children {
                position: relative;

                ul {
                    background-color: $io-blue-light;
                    display: none;
                    position: absolute;
                    white-space: nowrap;
                    @include box-shadow(0 5px 10px 0 rgba(0, 0, 0, 0.5));

                    li{
                        clear: left;
                        width: 100%;

                    }
                }

                &:hover {

                    ul {
                        display: block;
                        min-width: 100%;
                    }


                }
            }

            a {
                color: $io-white;
                font-size: 18px;
                padding: 10px 20px 18px;
                text-decoration: none;

                &:active,
                &:hover {
                    text-decoration: none;
                }

                @media (max-width: $screen-md-max) {
                    font-size: 16px;
                    padding: 14px 10px;
                }
            }
        }
    }
}

section {
    width: 100%;
}
