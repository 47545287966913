.page-heading {
    border-bottom: 1px solid $io-grey-light;
    letter-spacing: 0;
    line-height: 50px;
    //margin: 0 20px 3px;

    @media (max-width: $screen-xs-max) {
        margin: 0 5px 3px;
        padding: 10px 20px 0 20px;
        line-height: 27px;
        font-size: 19px;
    }

    @include clearfix(); // .heading-counter is using float-right;
    // This fixes element height when the is overflow
    span.lighter {
        color: $text-muted;
    }

    .store-counter {
        float: right;
        font-size: 20px;
        letter-spacing: 0;
    }
}

.page-subheading {
    border-bottom: 1px solid $io-grey-light;
    font-size: $font-size-h3;
    margin: 0 0 10px;
    padding: 0 0 8px;
}

.page-product-heading {
    border-bottom: 1px solid $io-grey-light;
    margin: 0 0 25px;
    padding: 20px 10px;
}

.page-product-subheading {
    border-bottom: 1px solid $io-grey-light;
    margin: 0 0 35px;
    padding: 20px 0;

    &.subtitled {
        padding: 20px 0 0;
    }

    .maincategory {
        color: $io-grey-dark;
        display: block;
        font-family: $font-default;
        font-size: 14px;
        letter-spacing: 0;
        margin: 7px 0 3px;
        text-transform: initial;
    }

    @media (max-width: $screen-xs-max) {
        padding-left: 10px;
        padding-right: 10px;

        &.subtitled {
            border: none;
            margin: 30px 0 20px;
            padding: 0;
        }
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: 1.5px;
}