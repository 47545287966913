.bottom-pagination-content {
    width: 100%;

    .product-count {
        float: left;
    }
}

.content_sortPagiBar {
    margin: 8px 0;

    .pagination {
        margin-top: 0;
        margin-bottom: 0;
    }

    #pagination {
        text-align: right;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .empty-search {
        padding: 10px 20px;
    }
}

#pagination_bottom {
    float: right;
    padding-right: 0;
    text-align: right;

    @media (max-width: $screen-xs-max) {
        float: none;
        text-align: center;
    }

    label {
        color: $io-grey-dark;
        display: inline-block;
        font-family: $font-heading;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: inherit;

        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    ul.pagination {
        li {
            @media (max-width: $screen-xs-max) {
                padding: 10px 10px 0;
            }
        }
    }
}

.pagination {
    li {
        display: inline-block;
        font-size: 14px;
        height: 34px;
        vertical-align: middle;
        @include transition(150ms ease);

        > a, > span {
            background: transparent;
            border: none;
            margin: 0;
            padding: 0;
            text-align: center;

            > span {
                background: transparent;
                border: none;
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }

        > a {
            &:hover {
                background: transparent;
                border: none;
            }
        }

        &.pagination_previous,
        &.pagination_next {
            a,
            span {
                display: block;
                height: 32px;
                width: 32px;
            }

            span {
                &:after {
                    background: transparent url(../img/arrow.svg) center no-repeat;
                    background-size: 20px;
                    border: 1px solid $io-grey-light;
                    content: '';
                    display: block;
                    height: 32px;
                    width: 32px;
                    @include transition(350ms ease);
                }

                &:hover {
                    &:after {
                        border-color: $io-grey-medium;
                    }
                }
            }
        }

        &.pagination_previous {
            span {
                &:after {
                    -webkit-transform: rotate(90deg) rotateX(0deg);
                    -ms-transform: rotate(90deg) rotateX(0deg); // IE9 only
                    -o-transform: rotate(90deg) rotateX(0deg);
                    transform: rotate(90deg) rotateX(0deg);
                }
            }
        }
        &.pagination_next {
            span {
                &:after {
                    -webkit-transform: rotate(90deg) rotateX(180deg);
                    -ms-transform: rotate(90deg) rotateX(180deg); // IE9 only
                    -o-transform: rotate(90deg) rotateX(180deg);
                    transform: rotate(90deg) rotateX(180deg);
                }
            }
        }
    }

    li.active {
        a,
        span,
        span:hover {
            background-color: $io-white;
            border: 1px solid $io-grey-light;
            color: $io-grey-dark !important;

            > span {
                border: none;
                background-color: transparent;
            }

            &:hover {
                background-color: $io-grey-light;
            }
        }
    }

    li.disabled {
        > span,
        > span:hover,
        > span:focus,
        > a,
        > a:hover,
        > a:focus {
            color: $io-grey-light;
            @include opacity(0.5);
        }

        span {
            &:hover {
                &:after {
                    border-color: $io-grey-light;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .top-pagination-content,
    .bottom-pagination-content {
        .form-group {
            margin-bottom: 20px; // Makes .form-group collapse nicely if they don't fit in SM width

            .form-control-static {
                min-height: 24px;
                padding: 1px 10px;
            }
        }
    }

    .showall {
        float: right;
    }

    .compare-form {
        float: right;
        margin-left: 10px;
    }
}