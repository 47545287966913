.instant_search {
    .productsSortForm {
        display: none;
    }
}

select.form-control {
    height: 24px;
    padding: 4px 10px;
}

@media (max-width: $screen-sm-max) {
    .sortPagiBar .form-group {
        margin-bottom: 20px; // Makes .form-group collapse nicely if they don't fit in SM width
        label {
            margin-right: 10px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}